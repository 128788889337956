import React from "react"
import { Link } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import Bar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import IconButton from "@material-ui/core/IconButton"
import ArrowBack from "@material-ui/icons/ArrowBack"
import CloseIcon from "@material-ui/icons/Close"
import EditIcon from "@material-ui/icons/Edit"

import Container from "./Container"

const AppBar = ({ backTo, closeTo, editTo, text, textTo, align, children, classes }) => (
  <Bar position="static" color="inherit" className={classes.wrapper}>
    <Container className="px-2">
      <Toolbar disableGutters className={classes.toolbar}>
        {backTo && (
          <IconButton component={Link} to={backTo}>
            <ArrowBack className={classes.icon} />
          </IconButton>
        )}
        <Typography
          noWrap={true}
          component={textTo ? Link : "p"}
          to={textTo}
          className={classes.header}
          align={align || "inherit"}
        >
          {text}
        </Typography>
        {children}
        {closeTo && (
          <IconButton component={Link} to={closeTo}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        )}
        {editTo && (
          <IconButton component={Link} to={editTo}>
            <EditIcon />
          </IconButton>
        )}
      </Toolbar>
    </Container>
  </Bar>
)

const styles = theme => ({
  wrapper: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  header: {
    flex: 1,
    padding: "10px 10px",
    textDecoration: "none",
    fontSize: 30,
    fontWeight: 900,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  toolbar: {
    minHeight: 170,
  },
  logo: {
    width: 120,
    maxWidth: "100%",
    display: "block",
    margin: "20px auto 25px",
    padding: 20,
  },
  icon: {
    fontSize: "30px",
  },
})

export default withStyles(styles)(AppBar)
