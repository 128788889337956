import createHistory from "history/createBrowserHistory"

const history = createHistory({
  basename: "/app",
})

var lastLocation = history.location

const updateLocation = (location, action) => {
  if (process.env.NODE_ENV === "development") {
    console.info(`%cHistory: ${action} ${location.pathname}`, "color:#825794; font-weight:bold;")
  }

  const locationChanged = location.pathname !== lastLocation.pathname
  lastLocation = location

  if ("REPLACE" === action || ("PUSH" === action && locationChanged)) {
    window.scrollTo(0, 0)
  }

  if ("START" === action || locationChanged) {
    // Send analytics...
  }
}

updateLocation(lastLocation, "START")
history.listen(updateLocation)

export default history
