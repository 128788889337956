import React from "react"
import classNames from "classnames"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import EventEmitter from "eventemitter3"

const EVENT = "NOTIFICATION"
const eventEmitter = new EventEmitter()
const queue = []

const initialState = {
  message: {},
  open: false,
}

let state = initialState

const emit = payload => {
  eventEmitter.emit(EVENT, { ...payload })
}

const processQueue = () => {
  if (queue.length > 0) {
    state = {
      message: queue.shift(),
      open: true,
    }
    emit(state)
  }
}

export const showMessage = (message = "", variant = "info") => {
  queue.push({ message, variant })

  if (state.open) {
    hideNotification()
  } else {
    processQueue()
  }
}

export const hideNotification = () => {
  state = {
    message: state.message,
    open: false,
  }
  emit(state)
}

export const connect = Component =>
  class extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = initialState
      this.subscription = this.subscription.bind(this)
    }
    componentWillMount() {
      eventEmitter.on(EVENT, this.subscription)
    }
    componentWillUnmount() {
      eventEmitter.removeListener(EVENT, this.subscription)
    }
    subscription(state) {
      this.setState(state)
    }
    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          processQueue={processQueue}
          hideMessage={hideNotification}
        />
      )
    }
  }

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles = theme => ({
  success: {
    color: theme.colors.lime.main,
  },
  error: {
    color: theme.colors.red.main,
  },
  info: {
    color: theme.colors.cyan.main,
  },
  warning: {
    color: theme.colors.gold.main,
  },
  iconVariant: {
    fontSize: 24,
    marginRight: 16,
  },
  icon: {
    fontSize: 20,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  hiddenMessage: {
    visibility: "hidden",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
})

class Notification extends React.Component {
  render() {
    const { classes, message, onClose, variant, ...rest } = this.props
    const Icon = variantIcon[variant]

    return (
      <SnackbarContent
        message={
          <span className={classes.message}>
            <Icon className={classNames(classes.iconVariant, classes[variant])} />
            {message}
          </span>
        }
        action={
          <IconButton color="inherit" className={classes.close} onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
        {...rest}
      />
    )
  }
}

class Notifications extends React.Component {
  handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      this.props.hideMessage()
    }
  }

  handleExited = () => {
    this.props.processQueue()
  }

  render() {
    const {
      open,
      message: { message, variant },
      classes: { hiddenMessage, ...classes },
    } = this.props

    const component = (
      <Notification
        classes={classes}
        onClose={this.handleClose}
        variant={variant}
        message={message}
      />
    )

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          onExited={this.handleExited}
          children={component}
        />
        {open && <div className={hiddenMessage} children={component} />}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(connect(Notifications))
