import moment from "moment"
import { api } from "../fetch"
import { interceptor } from "../forms"
import { showMessage } from "../notifications"
import { SIGNIN, SIGNOUT } from "./token"

export const FETCH_ACCOUNTS_REQUEST = "users/FETCH_ACCOUNTS_REQUEST"
export const FETCH_ACCOUNTS_SUCCESS = "users/FETCH_ACCOUNTS_SUCCESS"
export const FETCH_ACCOUNTS_FAILED = "users/FETCH_ACCOUNTS_FAILED"

export const ADD_ACCOUNT = "users/ADD_ACCOUNT"
export const UPDATE_ACCOUNT = "users/UPDATE_ACCOUNT"
export const DELETE_ACCOUNT = "users/DELETE_ACCOUNT"

const initialState = {
  list: [],
  isFetching: false,
  lastFetched: null,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      }

    case FETCH_ACCOUNTS_SUCCESS:
      return {
        list: action.list,
        isFetching: false,
        lastFetched: moment(),
        error: false,
      }

    case FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: true,
      }

    case ADD_ACCOUNT:
      return {
        ...state,
        list: [...state.list, action.account],
      }

    case UPDATE_ACCOUNT:
      return {
        ...state,
        list: state.list.map(account =>
          account.id !== action.id
            ? account
            : {
                ...account,
                ...action.account,
              }
        ),
      }

    case DELETE_ACCOUNT:
      return {
        ...state,
        list: state.list.filter(account => account.id !== action.id),
      }

    // Make sure to reset all data.
    case SIGNIN:
    case SIGNOUT:
      return initialState

    default:
      return state
  }
}

/**
 * Fetch all accounts.
 */
export const fetchAccounts = () => async dispatch => {
  dispatch({ type: FETCH_ACCOUNTS_REQUEST })
  try {
    const response = await dispatch(api("account"))
    if (!response.ok) throw response.statusText
    const list = await response.json()
    dispatch({ type: FETCH_ACCOUNTS_SUCCESS, list })
  } catch (err) {
    dispatch({ type: FETCH_ACCOUNTS_FAILED })
  }
}

/**
 * @FORM - Delete an account.
 * @param {Account} account
 */
export const deleteAccount = ({ id }) =>
  interceptor(async dispatch => {
    await dispatch(api("account/" + id, "delete"))
    dispatch({ type: DELETE_ACCOUNT, id })
    showMessage("Account verwijderd.", "success")
    return true
  }, false)

/**
 * @FORM - Update an account.
 * @param {Account} account
 */
export const updateAccount = ({ id, ...data }) =>
  interceptor(async dispatch => {
    const response = await dispatch(api("account/" + id, "patch", data))
    const account = await response.json()
    dispatch({ type: UPDATE_ACCOUNT, id, account })
    showMessage("Account gewijzigd.", "success")
    return account
  })

/**
 * @FORM - Save a account.
 * @param {Account} account
 */
export const saveAccount = data =>
  interceptor(async dispatch => {
    const response = await dispatch(api("account", "post", data))
    const account = await response.json()
    dispatch({ type: ADD_ACCOUNT, account })
    showMessage("Account opgeslagen.", "success")
    return account
  })
