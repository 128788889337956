import React from "react"

import { Link } from "react-router-dom"
import moment from "moment"
import trans from "../../../trans"

import AppBar from "../../AppBar"
import Container from "../../Container"
import Footer from "../../Footer"

import CreateUserForm from "../../../forms/CreateUserForm"
import CreateDateForm from "../../../forms/CreateDateForm"

import { withStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"
import ListItemIcon from "@material-ui/core/ListItemIcon"

class Group extends React.PureComponent {
  mounted = false

  constructor(props) {
    super(props)
    this.state = {
      addUser: false,
      addDate: false,
      showAllDates: false,
    }
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  // Date

  saveDate = async ({ date }) => {
    const { newGroupDate, group } = this.props
    await newGroupDate(group, {
      date: date.format("YYYY-MM-DD[T]HH:mm:ssZ"),
    })
    if (this.mounted) {
      this.setState({ addDate: false })
    }
  }

  toggleAddDate = () => {
    this.setState(state => ({ addDate: !state.addDate }))
  }

  removeDate = async date => {
    const { removeGroupDate, group } = this.props
    await removeGroupDate(group, date)
  }

  // USER

  saveUser = async values => {
    const { newGroupUser, group } = this.props
    await newGroupUser(group, values)
    if (this.mounted) {
      this.setState({ addUser: false })
    }
  }

  toggleAddUser = () => {
    this.setState({ addUser: !this.state.addUser })
  }

  addUser = async user => {
    const { addGroupUser, group } = this.props
    await addGroupUser(group, user)
  }

  removeUser = async user => {
    const { removeGroupUser, group } = this.props
    await removeGroupUser(group, user)
  }

  // RENDER

  renderDateListItem = ({ date: { date, past, id }, onClick }) => {
    return (
      <ListItem key={id} divider disabled={past} className="user-select-none">
        <ListItemIcon className="mr-1">
          <NotificationsActiveIcon />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography variant="subtitle1" noWrap>
            {date.format("dd DD MMM YYYY [om] HH:mm")}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton onClick={onClick}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderListItem = ({ user, onClick }) => {
    const userUrl = {
      pathname: `/gebruikers/${user.id}`,
      state: { previous: this.props.match.url },
    }
    return (
      <ListItem key={user.id} button divider onClick={onClick}>
        <ListItemText disableTypography>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="caption" noWrap>
            {"+" + user.phone}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton component={Link} to={userUrl}>
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  render() {
    const { classes, users, group } = this.props
    const { showAllDates } = this.state

    let recipients = []
    let absents = []

    users.list.forEach(user => {
      const recipient = group.users.filter(recipient => recipient.id === user.id)[0]
      if (recipient) {
        recipients.push({ ...user, created_at: moment(recipient.created_at) })
      } else {
        absents.push({ ...user, created_at: moment(user.created_at) })
      }
    })

    recipients = recipients.sort((lhs, rhs) => rhs.created_at.valueOf() - lhs.created_at.valueOf())
    absents = absents.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))

    let now = moment().startOf("minute")

    let dates = (group.dates || [])
      .map(({ date, ...props }) => {
        date = moment(date)
        return {
          ...props,
          date: date,
          past: date.endOf("minute").isBefore(now),
        }
      })
      .sort((lhs, rhs) => lhs.date.valueOf() - rhs.date.valueOf())

    let filteredDates = dates
      .filter(({ past }) => {
        return !past
      })
      .splice(0, 4)

    let canShowAllDates = dates.length !== filteredDates.length

    if (!showAllDates) {
      dates = filteredDates
    }

    return (
      <React.Fragment>
        <AppBar text={group.name} backTo="/groepen" editTo={`/groepen/${group.id}/wijzigen`} />
        <Container>
          <blockquote className={classes.blockquote}>
            <Typography className={classes.blockquoteTitle}>{group.message}</Typography>
            <Typography className={classes.blockquoteCaption} variant="caption">
              {`${trans(`NOTIFY_${group.notify}`)} naar ${trans(
                `WARN_${group.warn}`
              ).toLowerCase()}.`}
            </Typography>
          </blockquote>

          <Grid container spacing={16} className="mt-4">
            <Grid item xs={12} sm={6} className="mt-4">
              <Grid
                container
                spacing={16}
                alignItems="center"
                wrap="nowrap"
                zeroMinWidth
                justify="space-between"
                style={{ height: "60px" }}
              >
                <Grid item zeroMinWidth>
                  <Typography variant="h6" noWrap style={{ fontWeight: 900 }}>
                    {this.state.addDate ? "Bericht plannen" : "Geplande berichten"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="fab" color="primary" mini onClick={this.toggleAddDate}>
                    {this.state.addDate ? (
                      <CloseIcon style={{ fontSize: "32px" }} />
                    ) : (
                      <AddIcon style={{ fontSize: "32px" }} />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="caption">
                {this.state.addDate
                  ? "Nieuw bericht inplannen."
                  : "Momenten waarop dit bericht automatisch verstuurd word."}
              </Typography>

              <Card className="mt-3">
                {this.state.addDate ? (
                  <CardContent>
                    <CreateDateForm
                      onSubmit={this.saveDate}
                      initialValues={{
                        date: moment()
                          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
                          .add({ day: 1 }),
                      }}
                    />
                  </CardContent>
                ) : dates.length > 0 ? (
                  <List disablePadding>
                    {dates.map(date =>
                      this.renderDateListItem({
                        date,
                        onClick: e => {
                          this.removeDate(date)
                        },
                      })
                    )}
                  </List>
                ) : (
                  <List disablePadding>
                    <ListItem>
                      <Typography>
                        {showAllDates ? "Geen berichten" : "Geen geplande actuele berichten"}
                      </Typography>
                    </ListItem>
                  </List>
                )}
              </Card>
              {canShowAllDates ? (
                <Button
                  size="small"
                  color="primary"
                  className="mt-2"
                  onClick={e => {
                    e.preventDefault()
                    this.setState(({ showAllDates }) => ({ showAllDates: !showAllDates }))
                  }}
                >
                  {showAllDates ? "Toon minder" : "Toon alles"}
                </Button>
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={16} className="mt-4">
            <Grid item xs={12} sm={6} className="mt-4">
              <Grid
                container
                spacing={16}
                alignItems="center"
                wrap="nowrap"
                zeroMinWidth
                justify="space-between"
                style={{ height: "60px" }}
              >
                <Grid item zeroMinWidth>
                  <Typography variant="h6" noWrap style={{ fontWeight: 900 }}>
                    {this.state.addUser ? "Persoon toevoegen" : recipients.length + " Leden"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="fab" color="primary" mini onClick={this.toggleAddUser}>
                    {this.state.addUser ? (
                      <CloseIcon style={{ fontSize: "32px" }} />
                    ) : (
                      <AddIcon style={{ fontSize: "32px" }} />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="caption">
                {this.state.addUser
                  ? "Nieuw persoon lid maken van de groep."
                  : "Klik op een persoon om deze uit de groep te zetten."}
              </Typography>

              <Card className="mt-3">
                {this.state.addUser ? (
                  <CardContent>
                    <CreateUserForm onSubmit={this.saveUser} />
                  </CardContent>
                ) : (
                  <List disablePadding>
                    {recipients.map(user =>
                      this.renderListItem({
                        user,
                        onClick: e => {
                          this.removeUser(user)
                        },
                      })
                    )}
                  </List>
                )}
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} className="mt-4">
              <Grid
                container
                spacing={16}
                alignItems="center"
                wrap="nowrap"
                zeroMinWidth
                style={{ height: "60px" }}
              >
                <Grid item zeroMinWidth>
                  <Typography variant="h6" noWrap style={{ fontWeight: 900 }}>
                    {absents.length} Niet leden
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="caption">
                Klik op een lid om deze in de groep te zetten.
              </Typography>

              <Card className="mt-3">
                <List disablePadding>
                  {absents.map(user =>
                    this.renderListItem({
                      user,
                      onClick: e => {
                        this.addUser(user)
                      },
                    })
                  )}
                </List>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Footer />
      </React.Fragment>
    )
  }
}

const styles = {
  blockquote: {
    margin: 0,
  },
  blockquoteTitle: {
    fontSize: 32,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blockquoteCaption: {
    fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}

export default withStyles(styles)(Group)
