import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import themes from "./theme"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import store from "./store"
import history from "./history"

import { Provider } from "react-redux"
import { Router, withRouter } from "react-router-dom"
import Main from "./components/Main"
import Notifications from "./notifications"

import { MuiPickersUtilsProvider } from "material-ui-pickers"
import pickerUtils from "@date-io/moment"

// Languages
import "moment/locale/nl"

// CSS
import "./styles/index.css"

// Create the theme
const theme = createMuiTheme(themes)

// Function to render the application
const render = component => {
  const Component = withRouter(component)
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={pickerUtils}>
        <CssBaseline>
          <Provider store={store}>
            <Router history={history}>
              <Component />
            </Router>
          </Provider>
          <Notifications />
        </CssBaseline>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>,
    document.getElementById("root")
  )
}

// Render the application
render(Main)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

// // Render the application when files change while in development
// if (process.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./components/Main", () => {
//     render(require("./components/Main").default)
//   })
// }
