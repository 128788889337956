import { api, auth, req } from "../fetch"
import { interceptor } from "../forms"
import { showMessage } from "../notifications"
import { FetchError, SubmissionError } from "../errors"

export const SIGNIN = "token/SIGNIN" // Set the token after a signin.
export const REFRESH_TOKEN = "token/REFRESH_TOKEN" // Set the token after refresh token is used.
export const SIGNOUT = "token/SIGNOUT" // Remove the token after a signout.

const STORAGE_KEY = "token"

const initialState = (() => {
  const storage =
    window.localStorage.getItem(STORAGE_KEY) || window.sessionStorage.getItem(STORAGE_KEY)
  if (storage) {
    try {
      return JSON.parse(storage)
    } catch (e) {}
  }
  return null
})()

const clearTokenStorage = () => {
  window.localStorage.removeItem(STORAGE_KEY)
  window.sessionStorage.removeItem(STORAGE_KEY)
}

const updateTokenStorage = token => {
  clearTokenStorage()
  try {
    const storage = JSON.stringify(token)
    if (token.remember) {
      window.localStorage.setItem(STORAGE_KEY, storage)
    } else {
      window.sessionStorage.setItem(STORAGE_KEY, storage)
    }
  } catch (e) {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN:
    case REFRESH_TOKEN:
      if (action.token) {
        updateTokenStorage(action.token)
        return action.token
      } else {
        clearTokenStorage()
        return null
      }

    case SIGNOUT:
      clearTokenStorage()
      return null

    default:
      return state
  }
}

/**
 * @FORM - Signin.
 */
export const signin = (credentials, remember = false) =>
  interceptor(async dispatch => {
    try {
      await dispatch(auth(credentials, remember, true))
    } catch (error) {
      if (error instanceof FetchError && error.response.status === 401) {
        showMessage("Verkeerde e-mailadres of wachtwoord.", "error")
        throw new SubmissionError()
      }
      throw error
    }
  })

/**
 * @FORM - Reset password.
 */
export const resetPassword = (password, token) =>
  interceptor(async () => {
    const response = await req("/api/reset-password", "post", { password, token })
    const result = await response.json()
    showMessage("Wachtwoord opgeslagen.", "success")
    return result
  })

/**
 * Set auth token.
 */
export const setToken = (token, remember, signin) => {
  token = { ...token, remember }
  const type = signin ? SIGNIN : REFRESH_TOKEN
  return { type, token, remember }
}

/**
 * Remove auth token.
 */
export const removeToken = () => ({
  type: SIGNOUT,
})

/**
 * Signout.
 */
export const signout = () => async (dispatch, getState) => {
  if (null !== getState().token) {
    try {
      await dispatch(api("signout", "post"))
    } catch (err) {}
  }
  dispatch(removeToken())
  showMessage("Tot ziens.", "info")
}
