import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { saveGroup } from "../../reducers/groups"
import GroupForm from "../../forms/GroupForm"

import AppBar from "../AppBar"
import Container from "../Container"
import Footer from "../Footer"

class GroupCreate extends React.PureComponent {
  saveGroup = async values => {
    const { saveGroup, history } = this.props
    const group = await saveGroup(values)
    history.replace("/groepen/" + group.id)
  }

  render() {
    return (
      <React.Fragment>
        <AppBar text="Nieuwe Groep" closeTo="/groepen" />
        <Container>
          <GroupForm
            onSubmit={this.saveGroup}
            initialValues={{ warn: "ALL", notify: "SMS", color: "gold", visible: true }}
          />
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveGroup,
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(GroupCreate)
