import * as colors from "@material-ui/core/colors"

const colorPalette = (color, light = 300, main = 500, dark = 700, text = "#fff") => ({
  light: color[light],
  main: color[main],
  dark: color[dark],
  contrastText: text,
})

const palette = {
  gold: colorPalette(colors.amber),
  orange: colorPalette(colors.orange),
  red: colorPalette(colors.red),
  pink: colorPalette(colors.pink),
  purple: colorPalette(colors.purple, 300, 400, 600),
  violet: colorPalette(colors.deepPurple),
  blue: colorPalette(colors.blue),
  cyan: colorPalette(colors.cyan),
  green: colorPalette(colors.green),
  lime: colorPalette(colors.lightGreen),
  granite: colorPalette(colors.blueGrey),
}

const theme = {
  overrides: {
    MuiTypography: {
      root: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
      },
      subtitle1: {
        lineHeight: "1.5em",
      },
      h6: {
        fontWeight: 900,
        fontSize: "1.5rem",
        lineHeight: "1.16667em",
      },
      caption: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 4,
      },
    },
    MuiFormHelperText: {
      root: {
        minHeight: "1.5em",
        lineHeight: "1.5em",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: "none",
        fontSize: "1.15rem",
        padding: "6px 16px",
      },
      raised: {
        padding: "6px 16px",
        fontWeight: 900,
      },
      text: {
        padding: "6px 12px",
      },
      sizeSmall: {
        fontSize: "0.85rem",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0,0,0,0.05)",
        "&:hover": {
          backgroundColor: "rgba(0,0,0,0.1)",
        },
        "&$focused": {
          backgroundColor: "rgba(0,0,0,0.05)",
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: "10px 0 11px", // 6px 0 7px
        height: "1.5em",
        lineHeight: "normal",
      },
      multiline: {
        padding: "10px 0 11px", // 6px 0 7px
        lineHeight: "1.5em",
      },
      inputType: {
        height: "1.5em",
        lineHeight: "normal",
      },
    },
    MuiPrivateTextarea: {
      textarea: {
        lineHeight: "1.5em",
        maxHeight: "400px",
      },
    },
    MuiSelect: {
      select: {
        height: "1.5em",
        lineHeight: "normal",
        "&:focus": {
          background: null,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "translate(0, 32px) scale(1)",
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(0.875)",
      },
    },
    MuiDialog: {
      paper: {
        margin: "48px 20px",
      },
    },
    MuiDialogActions: {
      root: {
        margin: "4px 10px 12px",
      },
      action: {
        margin: 0,
        minWidth: 0,
      },
    },
    MuiDialogContentText: {
      root: {
        color: null,
        fontSize: "1.1rem",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#eaeaea",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 0,
        userSelect: "none",
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: "bold",
        color: "rgba(0, 0, 0, 0.87)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        right: 0,
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: undefined,
        },
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: "1px solid rgba(0,0,0,0.07)",
        "&:last-child": {
          borderBottom: "none",
        },
      },
      container: {
        "&:last-child > *:first-child": {
          borderBottom: "none",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        padding: "3px 24px",
        flexWrap: "nowrap",
      },
      action: {
        paddingLeft: "10px",
      },
    },
  },
  shadows: (() => {
    var shadows = ["none"],
      b,
      y,
      m = 3
    for (var i = 0; i < 24; i++) {
      b = i + m
      y = Math.floor(b / m)
      shadows.push(`0 ${y}px ${b}px rgba(0,0,0,.14)`)
    }
    return shadows
  })(),
  typography: {
    useNextVariants: true,
    fontFamily:
      'Circular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: 16,
  },
  palette: {
    primary: palette.green,
    secondary: palette.gold,
    error: palette.red,
    action: {
      disabled: "#ffffff",
      disabledBackground: "#b8c0c7",
    },
  },
  colors: palette,
}

export default theme
