import React from "react"

import CreateAccountForm from "../../forms/CreateAccountForm"

import AppBar from "../AppBar"
import Container from "../Container"
import Footer from "../Footer"

class CreateAccount extends React.PureComponent {
  saveAccount = async values => {
    const { saveAccount, history } = this.props
    await saveAccount(values)
    history.replace("/accounts")
  }

  render() {
    return (
      <React.Fragment>
        <AppBar text="Nieuw account" closeTo="/accounts" />
        <Container>
          <CreateAccountForm onSubmit={this.saveAccount} />
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default CreateAccount
