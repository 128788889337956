import React from "react"
import { connect } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import App from "./App"
import Auth from "./Auth/index"
import Authorized from "./Authorized"

class Main extends React.PureComponent {
  render() {
    const { authorized } = this.props
    const Component = authorized ? App : Auth
    return (
      <Switch>
        <Route path="/authorized/:token" exact component={Authorized} />
        <Route path="/" component={Component} />
        <Redirect to="/" />
      </Switch>
    )
  }
}

export default connect(state => ({
  authorized: null !== state.token,
}))(Main)

// import React from "react"
// import Avatar from "@material-ui/core/Avatar"
// import Button from "@material-ui/core/Button"
// import CssBaseline from "@material-ui/core/CssBaseline"
// import FormControl from "@material-ui/core/FormControl"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import Checkbox from "@material-ui/core/Checkbox"
// import Input from "@material-ui/core/Input"
// import InputLabel from "@material-ui/core/InputLabel"
// import LockIcon from "@material-ui/icons/LockOutlined"
// import Paper from "@material-ui/core/Paper"
// import Typography from "@material-ui/core/Typography"
// import withStyles from "@material-ui/core/styles/withStyles"

// function SignIn(props) {
//   const { classes } = props

//   return (
//     <main className={classes.main}>
//       <CssBaseline />
//       <Paper className={classes.paper}>
//         <Avatar className={classes.avatar}>
//           <LockIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5">
//           Sign in
//         </Typography>
//         <form className={classes.form}>
//           <FormControl margin="normal" required fullWidth>
//             <InputLabel htmlFor="email">Email Address</InputLabel>
//             <Input id="email" name="email" autoComplete="email" autoFocus />
//           </FormControl>
//           <FormControl margin="normal" required fullWidth>
//             <InputLabel htmlFor="password">Password</InputLabel>
//             <Input name="password" type="password" id="password" autoComplete="current-password" />
//           </FormControl>
//           <FormControlLabel
//             control={<Checkbox value="remember" color="primary" />}
//             label="Remember me"
//           />
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             color="primary"
//             className={classes.submit}
//           >
//             Sign in
//           </Button>
//         </form>
//       </Paper>
//     </main>
//   )
// }

// const styles = theme => ({
//   main: {
//     width: "auto",
//     display: "block", // Fix IE 11 issue.
//     marginLeft: theme.spacing.unit * 3,
//     marginRight: theme.spacing.unit * 3,
//     [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
//       width: 400,
//       marginLeft: "auto",
//       marginRight: "auto",
//     },
//   },
//   paper: {
//     marginTop: theme.spacing.unit * 8,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
//   },
//   avatar: {
//     margin: theme.spacing.unit,
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing.unit,
//   },
//   submit: {
//     marginTop: theme.spacing.unit * 3,
//   },
// })

// export default withStyles(styles)(SignIn)
