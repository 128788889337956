import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

export default ({ onClick }) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    direction="column"
    style={{ height: "90vh" }}
  >
    <Grid item className="mb-2">
      <Typography variant="h6">Oeps er ging iets mis.</Typography>
    </Grid>
    <Grid item>
      <Button onClick={onClick}>Probeer opnieuw</Button>
    </Grid>
  </Grid>
)
