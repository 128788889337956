import React from "react"
import { Link } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"

import Logo from "../assets/slimalert.png"

const LogoBar = ({ classes }) => (
  <div className={classes.wrapper}>
    <Link to="/" className={classes.link}>
      <img src={Logo} alt="SlimAlert" draggable="false" className={classes.logo} />
    </Link>
  </div>
)

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 170,
  },
  link: {
    width: 120,
    maxWidth: "100%",
    display: "block",
    marginTop: "10px",
    padding: "10px",
  },
  logo: {
    maxWidth: "100%",
  },
}

export default withStyles(styles)(LogoBar)
