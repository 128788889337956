import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Loading from "../Loading"
import ErrorRetry from "../ErrorRetry"

import EditUser from "./Edit"
import User from "./User"

import { fetchUsers, updateUser, deleteUser, inviteUser } from "../../reducers/users"

class Router extends React.PureComponent {
  previous = "/aanwezigheid"

  componentWillMount() {
    const { location } = this.props
    this.previous = (location && location.state && location.state.previous) || "/aanwezigheid"
    this.fetchAll()
  }

  fetchAll = () => {
    this.props.fetchUsers()
  }

  render() {
    const {
      users: { error, lastFetched },
      user,
      match: { path, url },
    } = this.props

    const previous = this.previous

    if (!lastFetched) {
      return error ? <ErrorRetry onClick={this.fetchAll} /> : <Loading />
    }

    if (!user) return <Redirect to={previous} />

    return (
      <Switch>
        <Route
          path={path + "/wijzigen"}
          exact
          render={props => <EditUser previous={previous} back={url} {...this.props} {...props} />}
        />
        <Route
          path={path}
          exact
          render={props => <User previous={previous} {...this.props} {...props} />}
        />
        <Redirect to={path} />
      </Switch>
    )
  }
}

const mapStateToProps = (state, props) => {
  const userid = Number(props.match.params.userid)
  return {
    users: state.users,
    user: state.users.list.filter(user => user.id === userid)[0],
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      updateUser,
      deleteUser,
      inviteUser,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router)
