import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { signout } from "../reducers/token"

import Container from "./Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Confirm from "../dialogs/Confirm"

class Footer extends React.PureComponent {
  state = {
    open: false,
  }
  openDialog = () => {
    this.setState({ open: true })
  }
  closeDialog = () => {
    this.setState({ open: false })
  }
  signout = () => {
    this.props.signout()
  }
  render() {
    const {
      user: { data: user },
    } = this.props
    return (
      <Container className="pt-5 pb-5">
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={true}>
            <Typography variant="caption" noWrap>
              &copy; slimalert.nl
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              <b>{user.name}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={this.openDialog} size="small">
              <Typography variant="caption">Afmelden</Typography>
            </Button>
          </Grid>
        </Grid>
        <Confirm
          open={this.state.open}
          maxWidth="xs"
          title="Afmelden"
          message={
            <React.Fragment>
              Weet je zeker dat je <b>{user.name}</b> wilt afmelden?
            </React.Fragment>
          }
          onClose={this.closeDialog}
          onClick={this.signout}
        />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  groups: state.groups,
  user: state.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signout,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
