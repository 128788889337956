import { combineReducers } from "redux"

import token from "./token"
import user from "./user"
import users from "./users"
import groups from "./groups"
import accounts from "./accounts"

const rootReducer = combineReducers({
  token,
  user,
  users,
  groups,
  accounts,
})

export default rootReducer
