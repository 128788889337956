import { FetchError, NetworkError } from "./errors"
import { setToken, removeToken } from "./reducers/token"

const HOST = window.location.protocol + "//" + window.location.host

/**
 * Promise of the pending token requests for synchronized token requests
 */

let refreshTokenRequest = null

const logRequest = async (url, { method }) => {
  if (process.env.NODE_ENV === "development") {
    console.info(`%cRequest: ${method || "GET"} ${url}`, "color:#0b929a; font-weight:bold;")
  }
}

/**
 * Token helpers
 */

const getAuthHeaders = token =>
  token ? { Authorization: `${token.token_type} ${token.access_token}` } : {}

const authToken = getState => {
  const token = getState().token
  if (token) return token
  throw new FetchError(new Response(null, { status: 401 }))
}

/**
 * Sanitize a Path
 *
 * @param path string
 * @return string
 */
const sanitizePath = path => ("/" + path.replace(/^\/+/, "")).replace(/\/+$/, "")

/**
 * Create Request Options
 *
 * @param method string
 * @param body RequestBody
 * @param options RequestOptions
 * @returns RequestOptions
 */
const requestOptions = (method, body, { headers, ...options } = {}) => {
  // Parse the method option.
  method = method ? method.toUpperCase() : "GET"

  // Set default headers.
  headers = {
    Accept: "application/json",
    ...(headers || {}),
  }

  // Check the body.
  if (null === body) {
    body = undefined
  } else if ("object" === typeof body && !(body instanceof FormData)) {
    // Set body option.
    body = JSON.stringify(body)
    // Set correct content-type header.
    headers = {
      "Content-Type": "application/json",
      ...headers,
    }
  }

  // Return request init object with defaults.
  return {
    mode: "same-origin",
    credentials: "omit",
    method,
    body,
    headers,
    ...options,
  }
}

const authRequestOptions = (token, options) => {
  return { ...options, headers: { ...options.headers, ...getAuthHeaders(token) } }
}

/**
 * Token Request
 *
 * @param body Object
 * @return Promise<Response>
 */
const fetchToken = (body, remember = true, signin = true) => async dispatch => {
  const endpoint = `${HOST}/api/signin`
  const options = requestOptions("post", body)

  logRequest(endpoint, options)
  const response = await fetch(endpoint, options)

  if (response.ok) {
    const token = await response.clone().json() // Clone to prevent: "body stream already read"
    dispatch(setToken(token, remember, signin))
  }

  return response
}

const refreshToken = token =>
  fetchToken({ refresh_token: token.refresh_token }, token.remember, false)

/**
 * Api Request
 *
 * @param path string
 * @param method string
 * @param body RequestBody
 * @param options RequestOptions
 * @return Promise<Response>
 */
const fetchApi = (path, method, body, options) => async (dispatch, getState) => {
  const token = authToken(getState)
  const endpoint = `${HOST}/api${sanitizePath(path)}`
  options = requestOptions(method, body, options)
  options = authRequestOptions(token, options)

  logRequest(endpoint, options)
  let response = await fetch(endpoint, options)

  if (response.status === 401) {
    // Create a refresh token request when not created already.
    if (null === refreshTokenRequest) {
      refreshTokenRequest = (async function() {
        try {
          const response = await dispatch(refreshToken(token))
          if (response.status === 401) {
            dispatch(removeToken())
          }
          refreshTokenRequest = null
          return response
        } catch (error) {
          refreshTokenRequest = null
          throw error
        }
      })()
    }

    // Wait for the refresh token request.
    response = await refreshTokenRequest

    // RefreshToken request OK. Retry the request with new accessToken.
    if (response.ok) {
      options = authRequestOptions(authToken(getState), options)
      logRequest(endpoint, options)
      response = await fetch(endpoint, options)
    }
  }

  // Return the response
  return response
}

/**
 * Api Request
 *
 * @param path string
 * @param method string
 * @param body RequestBody
 * @param options RequestOptions
 * @return Promise<Response>
 */
const fetchRequest = async (path, method, body, options) => {
  const url = HOST + sanitizePath(path)
  options = requestOptions(method, body, options)
  logRequest(path, options)
  const request = await fetch(url, options)
  return request
}

const handle = function(f) {
  return async function() {
    let error
    try {
      const response = await f.apply(this, arguments)
      if (response.ok) {
        return response
      }
      error = new FetchError(response)
    } catch (err) {
      error = navigator && false === navigator.onLine ? new NetworkError() : err
    }
    throw error
  }
}

/**
 * Export
 */

export const req = handle(fetchRequest)

export const api = function() {
  return handle(fetchApi.apply(this, arguments))
}

export const auth = function() {
  return handle(fetchToken.apply(this, arguments))
}
