import React from "react"

import EditAccountForm from "../../forms/EditAccountForm"

import AppBar from "../AppBar"
import Container from "../Container"
import Confirm from "../../dialogs/Confirm"
import Footer from "../Footer"

class EditGroup extends React.PureComponent {
  state = {
    open: false,
  }

  toggleConfirm = () => {
    this.setState({ open: !this.state.open })
  }

  deleteAccount = async () => {
    const { deleteAccount, history, account } = this.props
    if (await deleteAccount(account)) {
      history.replace("/accounts")
    }
  }

  updateAccount = async values => {
    const { updateAccount, history, account } = this.props
    await updateAccount({
      ...values,
      id: account.id,
    })
    history.replace("/accounts")
  }

  render() {
    const { account } = this.props
    return (
      <React.Fragment>
        <Confirm
          title="Account verwijderen"
          message="Weet je zeker dat je dit account definitief wilt verwijderen?"
          maxWidth="xs"
          onClose={this.toggleConfirm}
          onClick={this.deleteAccount}
          open={this.state.open}
        />
        <AppBar text="Account bewerken" closeTo="/accounts" />
        <Container>
          <EditAccountForm
            onSubmit={this.updateAccount}
            onDelete={this.toggleConfirm}
            initialValues={account}
          />
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default EditGroup
