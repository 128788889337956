import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import Groups from "./Groups"
import CreateGroup from "./Create"
import Group from "./Group/index"

const Router = () => (
  <Switch>
    <Route path="/groepen" exact component={Groups} />
    <Route path="/groepen/toevoegen" exact component={CreateGroup} />
    <Route path="/groepen/:groupid" component={Group} />
    <Redirect to="/groepen" />
  </Switch>
)

export default Router
