import React from "react"
import { Form, Field } from "react-final-form"
import { submission, isPassword } from "."
import PasswordField from "./fields/PasswordField"

import Button from "@material-ui/core/Button"

const validate = ({ password, confirm }) => {
  const errors = {}

  if (!isPassword(password)) {
    errors.password =
      "Wachtwoord moet minimaal 8 tekens bevatten. Combineer kleine letters met hoofdletters en gebruik minimaal 1 cijfer."
  } else if (password !== confirm) {
    errors.confirm = "Wachtwoorden zijn niet gelijk."
  }

  return errors
}

class ResetPasswordForm extends React.PureComponent {
  render() {
    const { onSubmit, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(onSubmit)}
        validate={validate}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate spellCheck="false">
              <Field name="token" type="hidden" component="input" />
              <PasswordField
                name="password"
                fullWidth
                label="Nieuw wachtwoord"
                placeholder="Wachtwoord"
                className="mb-4"
              />
              <PasswordField
                name="confirm"
                fullWidth
                label="Herhaal nieuw wachtwoord"
                placeholder="Wachtwoord"
                className="mb-4"
              />
              <Button type="submit" variant="contained" color="primary">
                Wachtwoord instellen
                {submitting && "..."}
              </Button>
            </form>
          )
        }}
      </Form>
    )
  }
}

export default ResetPasswordForm
