import React from "react"
import { Form } from "react-final-form"
import { submission, isPassword, isEmail } from "."
import TextField from "./fields/TextField"
import PasswordField from "./fields/PasswordField"
import trans from "../trans"

import Button from "@material-ui/core/Button"

const validate = ({ email, password, role }) => {
  const errors = {}

  if (!email) {
    errors.email = "Vul een e-mailadres in."
  } else if (!isEmail(email)) {
    errors.email = "Vul een geldig e-mailadres in."
  }

  if (!isPassword(password)) {
    errors.password =
      "Wachtwoord moet minimaal 8 tekens bevatten. Combineer kleine letters met hoofdletters en gebruik minimaal 1 cijfer."
  }

  if (!role) errors.role = "Maak een keuze."

  return errors
}

class CreateAccountForm extends React.PureComponent {
  render() {
    const { onSubmit, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(onSubmit)}
        validate={validate}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off" spellCheck="false">
              <TextField
                name="email"
                type="email"
                label="E-mailadres *"
                placeholder="E-mailadres"
                fullWidth
                className="mb-4"
              />
              <PasswordField
                name="password"
                label="Wachtwoord *"
                placeholder="Wachtwoord"
                fullWidth
                className="mb-4"
              />
              <TextField select native name="role" label="Account *" fullWidth className="mb-4">
                <option value="" disabled>
                  Maak een keuze...
                </option>
                <option value="DESK">{trans("ROLE_DESK")}</option>
                <option value="BOARD">{trans("ROLE_BOARD")}</option>
              </TextField>
              <Button type="submit" variant="contained" color="primary">
                Opslaan
                {submitting && "..."}
              </Button>
            </form>
          )
        }}
      </Form>
    )
  }
}

export default CreateAccountForm
