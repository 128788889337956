import React from "react"
import moment from "moment"
import { api } from "../fetch"
import { interceptor } from "../forms"
import { showMessage } from "../notifications"

import { SIGNIN, SIGNOUT } from "./token"

export const FETCH_USERS_REQUEST = "users/FETCH_USERS_REQUEST"
export const FETCH_USERS_SUCCESS = "users/FETCH_USERS_SUCCESS"
export const FETCH_USERS_FAILED = "users/FETCH_USERS_FAILED"

export const ADD_USER = "users/ADD_USER"
export const UPDATE_USER = "users/UPDATE_USER"
export const DELETE_USER = "users/DELETE_USER"

const initialState = {
  list: [],
  isFetching: false,
  lastFetched: null,
  error: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      }

    case FETCH_USERS_SUCCESS:
      return {
        list: action.list,
        isFetching: false,
        lastFetched: moment(),
        error: false,
      }

    case FETCH_USERS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: true,
      }

    case ADD_USER:
      return {
        ...state,
        list: [...state.list, action.user],
      }

    case UPDATE_USER:
      return {
        ...state,
        list: state.list.map(user =>
          user.id !== action.id
            ? user
            : {
                ...user,
                ...action.user,
              }
        ),
      }

    case DELETE_USER:
      return {
        ...state,
        list: state.list.filter(user => user.id !== action.id),
      }

    // Make sure to reset all data.
    case SIGNIN:
    case SIGNOUT:
      return initialState

    default:
      return state
  }
}

/**
 * Fetch all users.
 */
export const fetchUsers = () => async dispatch => {
  dispatch({ type: FETCH_USERS_REQUEST })
  try {
    let response = await dispatch(api("user"))
    if (!response.ok) throw response.statusText
    const list = await response.json()
    dispatch({ type: FETCH_USERS_SUCCESS, list })
  } catch (err) {
    dispatch({ type: FETCH_USERS_FAILED })
  }
}

/**
 * Update user presence.
 * @param {User} user
 */
export const updateUserPresence = ({ id, present }) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`user/${id}`, "patch", { present }))
    const user = await response.json()
    dispatch({ type: UPDATE_USER, id, user })
    showMessage(
      <span>
        <b>{user.name}</b> {present ? "aanwezig" : "afwezig"}.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * Delete user.
 * @param {User} user
 */
export const deleteUser = ({ id, name }) =>
  interceptor(async dispatch => {
    await dispatch(api(`user/${id}`, "delete"))
    dispatch({ type: DELETE_USER, id })
    showMessage(
      <span>
        <b>{name}</b> verwijderd.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * Invite a user.
 * @param {User} user
 */
export const inviteUser = ({ id, email }) =>
  interceptor(async dispatch => {
    await dispatch(api(`user/${id}/invite`, "post"))
    showMessage(
      <span>
        Uitnodiging verstuurd naar <b>{email}</b>.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * @FORM - Update a user.
 * @param {User} user
 */
export const updateUser = ({ id, ...data }) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`user/${id}`, "patch", data))
    const user = await response.json()
    dispatch({ type: UPDATE_USER, id, user })
    showMessage(
      <span>
        <b>{user.name}</b> gewijzigd.
      </span>,
      "success"
    )
    return user
  })
