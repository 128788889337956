const translations = {
  NOTIFY_SMS: "Tekst bericht",
  NOTIFY_CALL: "Spraak bericht",
  NOTIFY_ALL: "Tekst en spraak bericht",

  WARN_ALL: "Iedereen in deze groep",
  WARN_PRESENT: "Aanwezigen in deze groep",

  ROLE_USER: "Gebruiker",
  ROLE_DESK: "Aanwezigheid account",
  ROLE_BOARD: "Alarmering account",
  ROLE_OWNER: "Beheerder",

  COLOR_red: "Rood",
  COLOR_orange: "Oranje",
  COLOR_pink: "Framboos",
  COLOR_purple: "Paars",
  COLOR_violet: "Violet",
  COLOR_blue: "Blauw",
  COLOR_cyan: "Turquoise",
  COLOR_green: "Groen",
  COLOR_lime: "Limoen",
  COLOR_gold: "Goud",
  COLOR_granite: "Graniet",
}

const trans = key => (translations.hasOwnProperty(key) ? translations[key] : key)

export default trans
