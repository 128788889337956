import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import EditGroup from "./Edit"
import Group from "./Group"

import Loading from "../../Loading"
import ErrorRetry from "../../ErrorRetry"

import { fetchUsers } from "../../../reducers/users"

import {
  fetchGroups,
  addGroupUser,
  removeGroupUser,
  removeGroupDate,
  newGroupUser,
  newGroupDate,
  deleteGroup,
  updateGroup,
} from "../../../reducers/groups"

class Router extends React.PureComponent {
  componentWillMount() {
    this.fetchAll()
  }

  fetchAll = () => {
    this.props.fetchGroups()
    this.props.fetchUsers()
  }

  render() {
    const {
      users: { error: userError, lastFetched: userLastFetched },
      groups: { error: groupsError, lastFetched: groupsLastFetched },
      match: { path },
      group,
    } = this.props

    if (!userLastFetched || !groupsLastFetched) {
      return userError || groupsError ? <ErrorRetry onClick={this.fetchAll} /> : <Loading />
    }

    if (!group) return <Redirect to="/" />

    return (
      <Switch>
        <Route
          path={path + "/wijzigen"}
          exact
          render={props => <EditGroup {...this.props} {...props} />}
        />
        <Route path={path} exact render={props => <Group {...this.props} {...props} />} />
        <Redirect to={path} />
      </Switch>
    )
  }
}

const mapStateToProps = (state, props) => {
  const groupid = Number(props.match.params.groupid)
  return {
    users: state.users,
    groups: state.groups,
    group: state.groups.list.filter(group => group.id === groupid)[0],
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      fetchGroups,
      addGroupUser,
      removeGroupUser,
      newGroupUser,
      newGroupDate,
      removeGroupDate,
      deleteGroup,
      updateGroup,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router)
