import React from "react"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core"

const Loading = ({ classes }) => (
  <Grid container justify="center" alignItems="center" className={classes.container}>
    <Grid item>
      <CircularProgress className={classes.progress} thickness={5} />
    </Grid>
  </Grid>
)

const styles = {
  container: {
    height: "90vh",
  },
  progress: {
    width: 50,
    height: 50,
  },
}

export default withStyles(styles)(Loading)
