import ExtendableError from "es6-error"

export class FetchError extends ExtendableError {
  constructor(response) {
    super("FetchError")
    this.response = response
  }
}

export class NetworkError extends ExtendableError {
  constructor() {
    super("NetworkError")
  }
}

export class SubmissionError extends ExtendableError {
  constructor(errors = {}) {
    super("SubmissionError")
    this.errors = errors
  }
}

export const unknownFetchErrorMessage = function(response) {
  return `Oeps er is iets fout gegaan. (status: ${response.status})`
}

export const fetchErrorMessage = async function(response) {
  let seconds
  switch (response.status) {
    case 400:
      // Bad Request
      return "Dit verzoek is ongeldig."
    case 401:
      // Unauthorized
      return "Sessie verlopen. Log opnieuw in."
    case 403:
      // Forbidden
      return "Geen rechten voor dit verzoek."
    case 404:
      // Not Found
      return "Niet gevonden."
    case 422:
      // Unprocessable Entity
      const { errors } = await response.json()
      throw new SubmissionError(errors)
    case 423:
      // Locked
      seconds = response.headers.get("retry-after")
      return `Account is geblokkeerd. Je moet ${seconds} seconden wachten.`
    case 429:
      // Too Many Requests
      seconds = response.headers.get("retry-after")
      return `Te veel aanvragen. Je moet ${seconds} seconden wachten.`
    default:
      // Other
      return unknownFetchErrorMessage(response)
  }
}
