import React from "react"
import moment from "moment"
import { api } from "../fetch"
import { showMessage } from "../notifications"

import { SIGNIN, SIGNOUT } from "./token"

export const FETCH_ME_REQUEST = "users/FETCH_ME_REQUEST"
export const FETCH_ME_SUCCESS = "users/FETCH_ME_SUCCESS"
export const FETCH_ME_FAILED = "users/FETCH_ME_FAILED"

// Initial state
const initialState = {
  data: {},
  isFetching: false,
  lastFetched: null,
  error: false,
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ME_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      }

    case FETCH_ME_SUCCESS:
      return {
        data: action.user,
        isFetching: false,
        lastFetched: moment(),
        error: false,
      }

    case FETCH_ME_FAILED:
      return {
        ...state,
        isFetching: false,
        error: true,
      }

    // Make sure to reset all data.
    case SIGNIN:
    case SIGNOUT:
      return initialState

    default:
      return state
  }
}

/**
 * Fetch the authenticated user.
 */
export const fetchMe = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_ME_REQUEST })
  try {
    const response = await dispatch(api("me"))
    if (!response.ok) throw response.statusText
    const user = await response.json()
    const { lastFetched } = getState().user
    dispatch({ type: FETCH_ME_SUCCESS, user })
    if (null === lastFetched) {
      showMessage(
        <span>
          Welkom <b>{user.name}</b>.
        </span>,
        "info"
      )
    }
  } catch (err) {
    dispatch({ type: FETCH_ME_FAILED })
  }
}
