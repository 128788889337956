export default theme => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 900,
    fontSize: 24,
  },
  actions: {
    display: "flex",
  },
  message: {
    paddingTop: 12,
    paddingBottom: 23,
    marginBottom: "auto",
  },
  caption: {
    fontSize: 13,
    lineHeight: 1.5,
    maxWidth: 160,
  },
  moveIcon: {
    [theme.breakpoints.up("sm")]: {
      transform: "rotate(-90deg)",
    },
  },
  button: {
    padding: "10px 16px",
  },
  ...Object.keys(theme.colors).reduce((styles, key) => {
    const color = theme.colors[key]
    styles[`button_${key}`] = {
      color: color.contrastText,
      backgroundColor: color.main,
      "&:hover": {
        backgroundColor: color.dark,
        "@media (hover: none)": {
          backgroundColor: color.main,
        },
      },
    }
    return styles
  }, {}),
})
