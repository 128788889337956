import React from "react"
import { Form } from "react-final-form"
import { submission } from "."
import TextField from "./fields/TextField"

import {
  Button,
  Grid,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from "@material-ui/core"

const validate = ({ message }) => {
  const errors = {}
  if (!message || message.length <= 4) errors.message = "Vul een bericht in."
  return errors
}

class NotifyGroupForm extends React.PureComponent {
  render() {
    const { onSubmit, group, onCancel, editable, classes, ...props } = this.props
    return (
      <Form
        {...props}
        initialValues={{ message: group.message || "" }}
        onSubmit={submission(onSubmit)}
        validate={editable ? validate : null}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const groupName = group.name || ""
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogTitle>Melding versturen</DialogTitle>
              <DialogContent>
                <DialogContentText color="default" className="mr-4">
                  Weet je zeker dat je <b>{groupName}</b> een melding wilt sturen?
                </DialogContentText>
                {editable ? (
                  <div>
                    <TextField
                      name="message"
                      inputProps={{ maxLength: 160 }}
                      type="text"
                      variant="filled"
                      label="Bericht"
                      className="mt-3"
                      multiline
                      fullWidth
                    />
                    <Grid container spacing={8} className="mt-1 ml-1 mr-1">
                      <Grid item>
                        <Button
                          size="small"
                          color="primary"
                          variant="text"
                          className={classes.smallButton}
                          onClick={e => {
                            form.reset()
                          }}
                        >
                          Reset bericht
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          color="primary"
                          variant="text"
                          className={classes.smallButton}
                          onClick={e => {
                            form.change("message", "")
                          }}
                        >
                          Verwijder bericht
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={onCancel}>
                  Annuleren
                </Button>
                <Button type="submit" color="primary" variant="contained" className="ml-2">
                  Versturen
                  {submitting && "..."}
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Form>
    )
  }
}

const styles = theme => ({
  smallButton: {
    display: "inline-block",
    padding: "2px 6px",
    minHeight: 0,
    minWidth: 0,
  },
})

export default withStyles(styles)(NotifyGroupForm)
