import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import trans from "../../trans"

import AppBar from "../AppBar"
import Container from "../Container"
import Footer from "../Footer"

import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

class Accounts extends PureComponent {
  renderListItem = account => {
    return (
      <ListItem key={account.id} divider>
        <ListItemText disableTypography>
          <Typography variant="subtitle1" noWrap>
            {account.email}
          </Typography>
          <Typography style={{ color: "rgba(0,0,0,.56)" }} noWrap>
            {trans("ROLE_" + account.role)}
          </Typography>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton component={Link} to={"/accounts/" + account.id}>
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  render() {
    const { accounts } = this.props
    return (
      <div>
        <AppBar text="Accounts" backTo="/" logo>
          <IconButton component={Link} to="/accounts/toevoegen">
            <AddIcon style={{ fontSize: 32 }} />
          </IconButton>
        </AppBar>

        <Container>
          {accounts.length === 0 ? (
            <Card>
              <Typography variant="caption" align="center" className="px-2 py-4">
                Geen accounts
              </Typography>
            </Card>
          ) : (
            <Card>
              <List disablePadding>{accounts.map(account => this.renderListItem(account))}</List>
            </Card>
          )}
        </Container>

        <Footer />
      </div>
    )
  }
}

export default Accounts
