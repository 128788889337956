import React from "react"
import { Form } from "react-final-form"
import { submission, isPassword } from "."
import PasswordField from "./fields/PasswordField"

import { withStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"

const validate = ({ password }) => {
  const errors = {}

  if (!isPassword(password)) {
    errors.password =
      "Wachtwoord moet minimaal 8 tekens bevatten. Combineer kleine letters met hoofdletters en gebruik minimaal 1 cijfer."
  }

  return errors
}

class EditAccountForm extends React.PureComponent {
  render() {
    const { onSubmit, classes, onDelete, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(onSubmit)}
        validate={validate}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off" spellCheck="false">
              <PasswordField
                name="password"
                label="Wachtwoord *"
                placeholder="Wachtwoord"
                fullWidth
                className="mb-4"
              />
              <Grid container className={classes.buttons}>
                <Button type="submit" variant="contained" color="primary">
                  Opslaan
                  {submitting && "..."}
                </Button>
                <IconButton onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </form>
          )
        }}
      </Form>
    )
  }
}

const styles = {
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -4px",
    "& > button": {
      margin: "4px",
    },
  },
}

export default withStyles(styles)(EditAccountForm)
