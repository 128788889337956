import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { signout, setToken } from "../reducers/token"

class Authorized extends React.PureComponent {
  componentWillMount() {
    this.authorized()
  }

  authorized = async () => {
    const { match, history, signout, setToken } = this.props

    await signout()
    try {
      const token = JSON.parse(decodeURIComponent(match.params.token))
      await setToken(token, true, true)
    } catch (err) {}

    history.replace("/")
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signout,
      setToken,
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(Authorized)
