import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Route, Switch, Redirect } from "react-router-dom"

import { signout } from "../reducers/token"
import { fetchMe } from "../reducers/user"

import Loading from "./Loading"
import ErrorRetry from "./ErrorRetry"

import Home from "./Home"
import Groups from "./Groups/Groups"
import GroupsRouter from "./Groups/index"
import UsersRouter from "./Users/index"
import Presence from "./Presence/index"
import Accounts from "./Accounts/index"

class App extends PureComponent {
  componentWillMount() {
    this.fetchAll()
  }

  fetchAll = () => {
    this.props.fetchMe()
  }

  render() {
    const {
      user: { data: me, error, lastFetched },
    } = this.props

    if (!lastFetched) {
      return error ? <ErrorRetry onClick={this.fetchAll} /> : <Loading />
    }

    if (!me) {
      this.props.signout()
      return null
    }

    switch (me.role) {
      case "BOARD":
        return (
          <Switch>
            <Route path="/" exact component={Groups} />
            <Redirect to="/" />
          </Switch>
        )

      case "USER":
      case "DESK":
        return (
          <Switch>
            <Route path="/" exact component={Presence} />
            <Redirect to="/" />
          </Switch>
        )

      case "OWNER":
        return (
          <Switch>
            <Route path="/groepen" component={GroupsRouter} />
            <Route path="/gebruikers/:userid" component={UsersRouter} />
            <Route path="/aanwezigheid" exact component={Presence} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>
        )

      default:
        return null
    }
  }
}

const mapStateToProps = store => ({
  user: store.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMe,
      signout,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
