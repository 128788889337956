import React from "react"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

const Container = ({ classes, className, ...props }) => (
  <div {...props} className={classNames(classes.container, className)} />
)

const styles = {
  container: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth: 900,
  },
}

export default withStyles(styles)(Container)
