import React from "react"
import { Form } from "react-final-form"
import { submission, transformer, trimToString } from "."
import TextField from "./fields/TextField"
import CheckboxField from "./fields/CheckboxField"
import trans from "../trans"
import theme from "../theme"

import { withStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"

const transform = transformer(({ name, message, ...values }) => ({
  name: trimToString(name),
  message: trimToString(message),
  ...values,
}))

const validate = ({ name, message, color, notify, warn }) => {
  const errors = {}
  if (!name) errors.name = "Vul een groepsnaam in."
  if (!message) errors.message = "Vul een bericht in."
  if (!color) errors.color = "Kies een kleur."
  if (!notify) errors.notify = "Maak een keuze."
  if (!warn) errors.warn = "Maak een keuze."
  return errors
}

class GroupForm extends React.PureComponent {
  render() {
    const { onSubmit, onDelete, classes, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(transform(onSubmit))}
        validate={transform(validate)}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                name="name"
                inputProps={{ maxLength: 60 }}
                type="text"
                label="Naam *"
                placeholder="Naam"
                fullWidth
                className="mb-4"
              />
              <TextField
                name="message"
                inputProps={{ maxLength: 160 }}
                type="text"
                label="Bericht *"
                placeholder="Bericht"
                multiline
                fullWidth
                className="mb-4"
              />
              <TextField select native name="color" label="Kleur *" fullWidth className="mb-4">
                {Object.keys(theme.colors).map(key => (
                  <option key={key} value={key}>
                    {trans("COLOR_" + key)}
                  </option>
                ))}
              </TextField>
              <TextField
                select
                native
                name="notify"
                label="Groep informeren via *"
                placeholder="Maak een keuze"
                fullWidth
                className="mb-4"
              >
                {["SMS", "CALL", "ALL"].map(key => (
                  <option key={key} value={key}>
                    {trans("NOTIFY_" + key)}
                  </option>
                ))}
              </TextField>
              <TextField
                select
                native
                name="warn"
                label="Wie informeren *"
                fullWidth
                className="mb-4"
              >
                {["ALL", "PRESENT"].map(key => (
                  <option key={key} value={key}>
                    {trans("WARN_" + key)}
                  </option>
                ))}
              </TextField>
              <div className="mb-3">
                <FormControlLabel
                  label={
                    <div>
                      <Typography variant="body2">
                        <b>Zichtbaar</b>
                      </Typography>
                      <Typography variant="caption">
                        Bepaalt of deze groep zichtbaar is op het alarmeringen scherm.
                      </Typography>
                    </div>
                  }
                  control={<CheckboxField name="visible" color="primary" />}
                />
              </div>
              <div className="mb-4">
                <FormControlLabel
                  label={
                    <div>
                      <Typography variant="body2">
                        <b>Aanpasbaar bericht</b>
                      </Typography>
                      <Typography variant="caption">
                        Mogelijk om snel het bericht te aan te passen tijdens het verzenden van een
                        melding.
                      </Typography>
                    </div>
                  }
                  control={<CheckboxField name="message_editable" color="primary" />}
                />
              </div>
              <Grid container className={classes.buttons}>
                <Button type="submit" variant="contained" color="primary">
                  Opslaan
                  {submitting && "..."}
                </Button>
                {onDelete && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </form>
          )
        }}
      </Form>
    )
  }
}

const styles = {
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -4px",
    "& > button": {
      margin: "4px",
    },
  },
}

export default withStyles(styles)(GroupForm)
