import React, { PureComponent } from "react"

import AppBar from "../AppBar"
import Container from "../Container"
import Footer from "../Footer"

import Confirm from "../../dialogs/Confirm"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

const UserItem = ({ caption, text, ...props }) => (
  <div {...props}>
    <Typography variant="caption" noWrap className="mb-1">
      {caption}
    </Typography>
    <Typography variant="subtitle1" noWrap>
      {text}
    </Typography>
  </div>
)

const bool = value => (value ? "Ja" : "Nee")

class Group extends PureComponent {
  mounted = false

  constructor(props) {
    super(props)
    this.state = {
      openSendEmail: false,
    }
  }

  closeSendEmail = () => {
    this.setState({ openSendEmail: false })
  }

  openSendEmail = () => {
    this.setState({ openSendEmail: true })
  }

  sendEmail = () => {
    this.props.inviteUser(this.props.user)
    this.closeSendEmail()
  }

  render() {
    const {
      user,
      previous,
      match: { url },
    } = this.props
    const hasEmail = Boolean(user.email)
    const hasActivated = Boolean(user.password)
    return (
      <React.Fragment>
        <Confirm
          open={this.state.openSendEmail}
          maxWidth="xs"
          title="Versturen"
          message={
            <span>
              {`Een ${
                hasActivated ? "wachtwoord reset" : "account activatie"
              } mail versturen naar `}
              <b>{user.name}</b>? (<small>{user.email}</small>)
            </span>
          }
          onClose={this.closeSendEmail}
          onClick={this.sendEmail}
        />

        <AppBar text={user.name} backTo={previous} editTo={url + "/wijzigen"} />
        <Container>
          <Card>
            <CardContent>
              <UserItem caption="Naam" text={user.name} className="py-2" />
              <UserItem caption="Telefoonnummer" text={"+" + user.phone} className="py-2" />
              <UserItem caption="Aanwezig" text={bool(user.present)} className="py-2" />
              <UserItem
                caption="Aanwezigheid zichtbaar"
                text={bool(user.presence_visible)}
                className="py-2"
              />
              <UserItem caption="E-mailadres" text={user.email || "Nee"} className="py-2" />
              <UserItem caption="Geactiveerd account" text={bool(user.password)} className="py-2" />
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={this.openSendEmail}
                disabled={!hasEmail}
                className="mt-2"
              >
                {hasActivated
                  ? "Verstuur wachtwoord reset mail"
                  : "Verstuur account activatie mail"}
              </Button>
              {!hasEmail && (
                <Typography variant="caption" noWrap className="mt-1">
                  Stel eerst een e-mailadres in!
                </Typography>
              )}
            </CardContent>
          </Card>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Group
