import { showMessage } from "../notifications"
import {
  SubmissionError,
  FetchError,
  fetchErrorMessage,
  unknownFetchErrorMessage,
  NetworkError,
} from "../errors"

export const interceptor = function(action, reject) {
  return async function() {
    try {
      return await action.apply(this, arguments)
    } catch (err) {
      let message
      let error = err
      if (error instanceof FetchError) {
        try {
          message = await fetchErrorMessage(error.response)
        } catch (err) {
          message = unknownFetchErrorMessage(error.response)
          error = err
        }
      } else if (error instanceof NetworkError) {
        message = "Geen verbinding. Probeer het opnieuw."
      } else {
        message = "Oeps er is iets fout gegaan."
      }

      // Throw error when instanceof SubmissionError.
      if (error instanceof SubmissionError) {
        throw error
      }

      // Show the error message.
      showMessage(message, "error")

      // Handle the rejection.
      switch (typeof reject) {
        case "undefined":
          throw error
        case "function":
          return reject(error)
        default:
          return reject
      }
    }
  }
}

export const submission = submitHandler => async values => {
  try {
    await submitHandler(values)
  } catch (error) {
    if (error instanceof SubmissionError) {
      return error.errors
    }
  }
}

export const transformer = transform => submitHandler => (values, ...args) =>
  submitHandler(transform(values), ...args)

export const isEmail = value => {
  if ("string" !== typeof value) return false
  const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i
  return pattern.test(value)
}

export const isString = value => "string" === typeof value

export const isPassword = value => {
  if ("string" !== typeof value) return false
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
  return pattern.test(value)
}

export const isPhone = value => {
  if ("string" !== typeof value) return false
  const pattern = /^[1-9][0-9]{10,12}$/
  return pattern.test(value)
}

export const toString = value => {
  switch (typeof value) {
    case "string":
      return value
    case "number":
      return value.toString()
    default:
      return ""
  }
}

export const trimToString = value => {
  switch (typeof value) {
    case "string":
      return value.trim()
    case "number":
      return value.toString()
    default:
      return ""
  }
}

export const toPhone = value => {
  const digits = toString(value).replace(/\D/g, "")
  const number = parseInt(digits, 10).toString()
  if (number.length === 9 && digits.charAt(0) === "0") {
    return "31" + number
  }
  return number
}
