import React from "react"
import { Form } from "react-final-form"
import { submission } from "."
import TextField from "./fields/TextField"
import CheckboxField from "./fields/CheckboxField"
import PasswordField from "./fields/PasswordField"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"

const validate = ({ username, password }) => {
  const errors = {}
  if (!username) errors.username = "Vul je e-mailadres in."
  if (!password) errors.password = "Vul je wachtwoord in."
  return errors
}

class SigninForm extends React.PureComponent {
  render() {
    const { onSubmit, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(onSubmit)}
        validate={validate}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate spellCheck="false">
              <TextField
                name="username"
                type="email"
                fullWidth
                label="E-mailadres"
                placeholder="E-mailadres"
                className="mb-4"
              />
              <PasswordField
                name="password"
                fullWidth
                label="Wachtwoord"
                placeholder="Wachtwoord"
                className="mb-4"
              />
              <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                <Grid item zeroMinWidth>
                  <CheckboxField
                    style={{ maxWidth: "100%" }}
                    name="remember"
                    label={<b>Mij onthouden</b>}
                    color="primary"
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Inloggen
                    {submitting && "..."}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Form>
    )
  }
}

export default SigninForm
