import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { fetchUsers, updateUserPresence } from "../../reducers/users"

import AppBar from "../AppBar"
import LogoBar from "../LogoBar"
import Container from "../Container"
import Loading from "../Loading"
import ErrorRetry from "../ErrorRetry"
import Footer from "../Footer"

import { withStyles } from "@material-ui/core"

import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"

import LockOpenIcon from "@material-ui/icons/LockOpen"
import LockIcon from "@material-ui/icons/Lock"
import EditIcon from "@material-ui/icons/Edit"

class Presence extends React.PureComponent {
  isBusy = false
  refreshTimer = null
  refreshInterval = 5000

  componentWillMount() {
    this.fetchAll()
  }

  componentDidMount() {
    this.refreshTimer = setInterval(() => this.fetchAll(), this.refreshInterval)
  }

  componentWillUnmount() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  }

  fetchAll = () => {
    this.props.fetchUsers()
  }

  toggle = user => async () => {
    if (this.isBusy) return
    this.isBusy = true
    await this.props.updateUserPresence({
      ...user,
      present: !user.present,
    })
    this.isBusy = false
  }

  renderPresenceTab = ({ className, longText, shortText }) => {
    const { classes } = this.props
    return (
      <div className={classNames(classes.presenceTab, className)}>
        <Typography
          className={`${classes.presenceText} ${classes.presenceLongText}`}
          noWrap={true}
          variant="button"
          color="inherit"
          children={longText}
        />
        <Typography
          className={`${classes.presenceText} ${classes.presenceShortText}`}
          noWrap={true}
          variant="button"
          color="inherit"
          children={shortText}
        />
      </div>
    )
  }

  render() {
    const {
      user: { data: me },
      users: { list: users, error, lastFetched },
      classes,
    } = this.props

    if (!lastFetched) {
      return error ? <ErrorRetry onClick={this.fetchAll} /> : <Loading />
    }

    const isOwner = me.role === "OWNER"
    const isDesk = me.role === "DESK"
    const sortedUsers = users.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))

    return (
      <React.Fragment>
        {isOwner ? <AppBar backTo="/" text="Aanwezigheid" /> : <LogoBar />}
        <Container style={isOwner ? undefined : { maxWidth: 700 }}>
          <Card>
            {sortedUsers.length === 0 ? (
              <Typography variant="caption" align="center" className="px-2 py-4">
                Geen leden
              </Typography>
            ) : (
              <List disablePadding>
                {sortedUsers.map(user => {
                  const isMe = me.id === user.id
                  const enabled = isOwner || isDesk || isMe
                  const present = user.present === true
                  const visible = user.presence_visible === true
                  const toggle = enabled ? this.toggle(user) : undefined
                  return (
                    <ListItem
                      divider={true}
                      key={user.id}
                      button={enabled}
                      onClick={toggle}
                      classes={{
                        root: classNames(classes.listItem, isOwner && classes.listItemEditable),
                      }}
                    >
                      {isOwner && (
                        <Tooltip title={`Aanwezigheid ${visible ? "" : "niet "}zichtbaar`}>
                          <ListItemIcon>{visible ? <LockOpenIcon /> : <LockIcon />}</ListItemIcon>
                        </Tooltip>
                      )}
                      <Typography
                        variant="subtitle1"
                        noWrap={true}
                        className={classNames(
                          classes.title,
                          isMe && classes.titleStrong,
                          enabled || classes.titleDisabled
                        )}
                        children={user.name}
                      />
                      <div className={classes.presenceTabs}>
                        {this.renderPresenceTab({
                          className: present && classes.presenceTabPresent,
                          longText: "Aanwezig",
                          shortText: "In",
                        })}
                        {this.renderPresenceTab({
                          className: present || classes.presenceTabAbsent,
                          longText: "Afwezig",
                          shortText: "Uit",
                        })}
                      </div>
                      {isOwner && (
                        <ListItemSecondaryAction>
                          <Tooltip title="Contact wijzigen">
                            <IconButton
                              component={Link}
                              to={{
                                pathname: `/gebruikers/${user.id}`,
                                state: { previous: "/aanwezigheid" },
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Card>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

const styles = theme => ({
  title: {
    flex: "1 1 auto",
  },
  titleStrong: {
    fontWeight: 900,
  },
  titleDisabled: {
    color: "rgba(0,0,0,.54)",
  },
  listItem: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
  },
  listItemEditable: {
    paddingRight: 57,
  },
  presenceTabs: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginLeft: 10,
  },
  presenceTab: {
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    minHeight: 38,
    color: "rgba(0,0,0,.1)",
    padding: "8px 6px",
    [theme.breakpoints.up(360)]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  presenceTabPresent: {
    color: "#fff",
    backgroundColor: theme.colors.lime.main,
  },
  presenceTabAbsent: {
    color: "#fff",
    backgroundColor: theme.colors.red.main,
  },
  presenceText: {
    fontWize: 15,
    fontWeight: 600,
    textTransform: "none",
  },
  presenceLongText: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  presenceShortText: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
})

const mapStateToProps = store => ({
  user: store.user,
  users: store.users,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      updateUserPresence,
    },
    dispatch
  )

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Presence)
)
