import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styles from "./Group.styles"

import trans from "../../trans"
import { fetchGroups, updateGroup, notifyGroup, sortGroup } from "../../reducers/groups"
import { signout } from "../../reducers/token"

import AppBar from "../AppBar"
import LogoBar from "../LogoBar"
import Container from "../Container"
import Loading from "../Loading"
import ErrorRetry from "../ErrorRetry"
import Footer from "../Footer"

import { withStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Tooltip from "@material-ui/core/Tooltip"
import Divider from "@material-ui/core/Divider"

import AddIcon from "@material-ui/icons/Add"
import EditIcon from "@material-ui/icons/Edit"
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import LockIcon from "@material-ui/icons/Lock"
import { Dialog } from "@material-ui/core"
import NotifyGroupForm from "../../forms/NotifyGroupForm"

const NotifyCard = withStyles(styles)(
  class extends React.PureComponent {
    render() {
      const { isOwner, group, moveUp, moveDown, notify, toggleVisibility, classes } = this.props
      const caption = isOwner
        ? `${trans(`NOTIFY_${group.notify}`)} naar ${trans(`WARN_${group.warn}`).toLowerCase()}.`
        : `Bericht ${trans("WARN_" + group.warn).toLowerCase()} .`

      return (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" className={classes.title} children={group.name} />
            <Typography noWrap={true} className={classes.message} children={group.message} />
            <Grid
              container
              spacing={16}
              alignItems="center"
              zeroMinWidth
              className={classes.footer}
              wrap="nowrap"
            >
              <Grid item>
                <Button
                  className={[classes.button, classes[`button_${group.color}`]].join(" ")}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={notify}
                  children="Alert"
                />
              </Grid>
              <Grid item zeroMinWidth>
                <Typography variant="caption" className={classes.caption} children={caption} />
              </Grid>
            </Grid>
          </CardContent>
          {isOwner && (
            <React.Fragment>
              <Divider className="mt-1" />
              <CardActions disableActionSpacing={true} className="py-1">
                {moveUp !== null && (
                  <Tooltip title="Verplaatsen">
                    <IconButton onClick={moveUp}>
                      <ArrowUpIcon className={classes.moveIcon} />
                    </IconButton>
                  </Tooltip>
                )}
                {moveDown !== null && (
                  <Tooltip title="Verplaatsen">
                    <IconButton onClick={moveDown}>
                      <ArrowDownIcon className={classes.moveIcon} />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={group.visible ? "Zichtbaar" : "Niet zichtbaar"}>
                  <IconButton onClick={toggleVisibility} style={{ marginLeft: "auto" }}>
                    {group.visible ? <LockOpenIcon /> : <LockIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Wijzigen">
                  <IconButton component={Link} to={`/groepen/${group.id}`}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </React.Fragment>
          )}
        </Card>
      )
    }
  }
)

class Groups extends React.PureComponent {
  mounted = false
  refreshTimer = null
  refreshInterval = 5000

  constructor(props) {
    super(props)
    this.state = {
      open: false, // Confirm alert open
      group: null, // Confirm alert group
    }
    this.mounted = true
  }

  componentWillMount() {
    this.fetchAll()
  }

  componentDidMount() {
    this.refreshTimer = setInterval(() => this.fetchAll(), this.refreshInterval)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  }

  setStateSafe = (state, resolve) => {
    if (this.mounted) {
      this.setState(state, resolve)
    }
  }

  fetchAll = () => {
    this.props.fetchGroups()
  }

  toggleVisibility = group => async () => {
    try {
      await this.props.updateGroup({
        id: group.id,
        visible: !group.visible,
      })
      return true
    } catch (err) {
      return false
    }
  }

  confirmNotify = group => () => {
    this.setStateSafe({ open: true, group: { ...group } })
  }

  sort = (group, position) => {
    const size = this.props.groups.list.length
    return position >= 0 && position < size
      ? () => {
          this.props.sortGroup(group, position)
        }
      : null
  }

  notify = async values => {
    let { group } = this.state

    if (!group) {
      this.setStateSafe({ open: false })
      return
    }

    if (group.message_editable) {
      group = {
        ...group,
        message: values.message || group.message,
      }
    }

    let success = await this.props.notifyGroup(group)

    if (
      success &&
      this.state.open &&
      null !== this.state.group &&
      this.state.group.id === group.id
    ) {
      this.setStateSafe({ open: false })
    }
  }

  closeDialog = () => {
    this.setStateSafe({
      open: false,
    })
  }

  render() {
    const {
      user: { data: user },
      groups: { list: groups, error, lastFetched },
    } = this.props

    if (!lastFetched) {
      return error ? <ErrorRetry onClick={this.fetchAll} /> : <Loading />
    }

    const sortedGroups = groups.sort((lhs, rhs) =>
      (lhs.position !== rhs.position ? lhs.position > rhs.position : lhs.id > rhs.id) ? 1 : -1
    )

    const isOwner = user.role === "OWNER"
    const hasSelectedGroup = null != this.state.group
    const selectedGroup = hasSelectedGroup ? this.state.group : {}
    const selectedGroupIsEditable = true === selectedGroup.message_editable

    return (
      <div>
        <Dialog
          onClose={this.closeDialog}
          open={hasSelectedGroup && this.state.open}
          maxWidth={selectedGroupIsEditable ? "md" : "xs"}
        >
          <NotifyGroupForm
            onSubmit={this.notify}
            group={selectedGroup}
            editable={selectedGroupIsEditable}
            onCancel={this.closeDialog}
          />
        </Dialog>

        {isOwner ? (
          <AppBar text="Alarmeringen" backTo="/">
            <IconButton component={Link} to="/groepen/toevoegen">
              <AddIcon style={{ fontSize: 32 }} />
            </IconButton>
          </AppBar>
        ) : (
          <LogoBar />
        )}

        <Container>
          {sortedGroups.length === 0 ? (
            <Card>
              <Typography variant="caption" align="center" className="px-2 py-4">
                Geen groepen
              </Typography>
            </Card>
          ) : (
            <Grid container spacing={16}>
              {sortedGroups.map((group, position) => (
                <Grid key={`${group.id}@${position}`} item xs={12} sm={6} md={4}>
                  <NotifyCard
                    key={group.id}
                    group={group}
                    isOwner={isOwner}
                    position={position}
                    notify={this.confirmNotify(group)}
                    toggleVisibility={this.toggleVisibility(group)}
                    moveUp={this.sort(group, position - 1)}
                    moveDown={this.sort(group, position + 1)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>

        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  groups: state.groups,
  user: state.user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGroups,
      updateGroup,
      sortGroup,
      notifyGroup,
      signout,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups)
