import React from "react"
import { Field, Form } from "react-final-form"
import { submission } from "."

import Button from "@material-ui/core/Button"
import MuiTextField from "@material-ui/core/TextField"
import { Grid } from "@material-ui/core"
import { DatePicker } from "material-ui-pickers"
import moment from "moment"

class DateField extends React.PureComponent {
  render() {
    const { error: errorProp, helperText: helperTextProp, ...props } = this.props
    return (
      <Field {...props}>
        {({
          input: { value, onChange, onBlur },
          meta: { touched, error: validationError, submitError, inputOnChange, ...rest },
        }) => {
          const formError = submitError || (touched && validationError)
          const error = errorProp || Boolean(formError)
          const helperText = formError || helperTextProp

          value = moment.isMoment(value) ? value : moment()

          let changeDate = date => {
            onBlur()
            onChange(date)
          }

          return (
            <Grid container className="mb-4" spacing={8}>
              <Grid item xs={true}>
                <DatePicker
                  helperText={helperText}
                  labelFunc={date => {
                    return date.format("DD MMM YYYY")
                  }}
                  error={error}
                  fullWidth
                  minDate={moment().startOf("day")}
                  maxDate={moment().add({ years: 100 })}
                  label="Datum"
                  onlyCalendar={true}
                  animateYearScrolling={false}
                  openToYearSelection={false}
                  value={value}
                  onChange={changeDate}
                />
              </Grid>
              <Grid item xs="auto">
                <MuiTextField
                  error={error}
                  select
                  native
                  name="day"
                  label="Uur"
                  value={value.format("HH")}
                  fullWidth
                  SelectProps={{ native: true }}
                  onChange={e => {
                    e.preventDefault()
                    let date = moment(value)
                    date.set({ hours: parseInt(e.target.value, 10) })
                    changeDate(date)
                  }}
                >
                  {[
                    "00",
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                  ].map(key => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </MuiTextField>
              </Grid>
              <Grid item xs="auto">
                <MuiTextField
                  error={error}
                  select
                  native
                  fullWidth
                  name="hour"
                  label="Min"
                  value={value.format("mm")}
                  SelectProps={{
                    native: true,
                  }}
                  onChange={e => {
                    e.preventDefault()
                    let date = moment(value)
                    date.set({ minutes: parseInt(e.target.value, 10) })
                    changeDate(date)
                  }}
                >
                  {["00", "05", "10", "15", "25", "30", "35", "40", "45", "50", "55"].map(key => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </MuiTextField>
              </Grid>
            </Grid>
          )
        }}
      </Field>
    )
  }
}

const validate = ({ date }) => {
  const errors = {}
  if (!moment.isMoment(date)) errors.date = "Vul een datum in."
  else if (!date.isAfter(moment())) errors.date = "Datum moet in de toekomst liggen."
  return errors
}

class CreateDateForm extends React.PureComponent {
  render() {
    const { onSubmit, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(onSubmit)}
        validate={validate}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DateField name="date" />
              <Button type="submit" fullWidth variant="contained" color="primary">
                Opslaan
                {submitting && "..."}
              </Button>
            </form>
          )
        }}
      </Form>
    )
  }
}

export default CreateDateForm
