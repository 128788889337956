import React from "react"
import TextField from "./TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

export default class PasswordField extends React.PureComponent {
  state = {
    secure: true,
  }

  onMouseDown = event => {
    event.preventDefault()
  }

  onClick = () => {
    this.setState(state => ({ secure: !state.secure }))
  }

  render() {
    const { secure } = this.state
    return (
      <TextField
        type={secure ? "password" : "text"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={this.onClick} onMouseDown={this.onMouseDown} tabIndex="-1">
              {secure ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...this.props}
      />
    )
  }
}
