import React from "react"
import moment from "moment"
import { api } from "../fetch"
import { interceptor } from "../forms"
import { showMessage } from "../notifications"

import { SIGNIN, SIGNOUT } from "./token"
import { ADD_USER } from "./users"

export const FETCH_GROUPS_REQUEST = "groups/FETCH_GROUPS_REQUEST"
export const FETCH_GROUPS_SUCCESS = "groups/FETCH_GROUPS_SUCCESS"
export const FETCH_GROUPS_FAILED = "groups/FETCH_GROUPS_FAILED"

export const FETCH_GROUP_REQUEST = "groups/FETCH_GROUP_REQUEST"
export const FETCH_GROUP_SUCCESS = "groups/FETCH_GROUP_SUCCESS"
export const FETCH_GROUP_FAILED = "groups/FETCH_GROUP_FAILED"

export const ADD_GROUP = "groups/ADD_GROUP"
export const UPDATE_GROUP = "groups/UPDATE_GROUP"
export const SORT_GROUP = "groups/SORT_GROUP"
export const DELETE_GROUP = "groups/DELETE_GROUP"

export const ADD_GROUP_USER = "groups/ADD_GROUP_USER"
export const REMOVE_GROUP_USER = "groups/REMOVE_GROUP_USER"

export const ADD_GROUP_DATE = "groups/ADD_GROUP_DATE"
export const REMOVE_GROUP_DATE = "groups/REMOVE_GROUP_DATE"

const initialState = {
  list: [],
  isFetching: false,
  lastFetched: null,
  error: false,
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
      }

    case FETCH_GROUPS_SUCCESS:
      return {
        list: action.list,
        isFetching: false,
        lastFetched: moment(),
        error: false,
      }

    case FETCH_GROUPS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: true,
      }

    case ADD_GROUP:
      return {
        ...state,
        list: [...state.list, action.group],
      }

    case UPDATE_GROUP:
      return {
        ...state,
        list: state.list.map(group =>
          group.id !== action.id
            ? group
            : {
                ...group,
                ...action.group,
              }
        ),
      }

    case SORT_GROUP:
      return {
        ...state,
        list: state.list.reduce((list, group) => {
          const position = action.positions.indexOf(group.id)
          if (position !== -1) {
            list.push({ ...group, position })
          }
          return list
        }, []),
      }

    case DELETE_GROUP:
      return {
        ...state,
        list: state.list.filter(group => group.id !== action.id),
      }

    case ADD_GROUP_USER:
      return {
        ...state,
        list: state.list.map(group =>
          group.id !== action.id
            ? group
            : {
                ...group,
                users: [...group.users, action.user],
              }
        ),
      }

    case REMOVE_GROUP_USER:
      return {
        ...state,
        list: state.list.map(group =>
          group.id !== action.id
            ? group
            : {
                ...group,
                users: group.users.filter(user => user.id !== action.userid),
              }
        ),
      }

    case ADD_GROUP_DATE:
      return {
        ...state,
        list: state.list.map(group =>
          group.id !== action.id
            ? group
            : {
                ...group,
                dates: [...group.dates, action.date],
              }
        ),
      }

    case REMOVE_GROUP_DATE:
      return {
        ...state,
        list: state.list.map(group =>
          group.id !== action.id
            ? group
            : {
                ...group,
                dates: group.dates.filter(date => date.id !== action.dateid),
              }
        ),
      }

    case SIGNIN:
    case SIGNOUT:
      return initialState

    default:
      return state
  }
}

/**
 * Fetch all groups.
 */
export const fetchGroups = () => async dispatch => {
  dispatch({ type: FETCH_GROUPS_REQUEST })
  try {
    const response = await dispatch(api("group"))
    if (!response.ok) throw response.statusText
    const list = await response.json()
    dispatch({ type: FETCH_GROUPS_SUCCESS, list })
  } catch (err) {
    dispatch({ type: FETCH_GROUPS_FAILED })
  }
}

/**
 * Add a user to a group.
 * @param {Group} group
 * @param {User} user
 */
export const addGroupUser = ({ id }, { id: userid, name: username }) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`group/${id}/user/${userid}`, "post"))
    const result = await response.json()
    dispatch({ type: ADD_GROUP_USER, id, user: result })
    showMessage(
      <span>
        <b>{username}</b> toegevoegd aan groep.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * Remove a user from a group.
 * @param {Group} group
 * @param {User} user
 */
export const removeGroupUser = ({ id }, { id: userid, name: username }) =>
  interceptor(async dispatch => {
    await dispatch(api(`group/${id}/user/${userid}`, "delete"))
    dispatch({ type: REMOVE_GROUP_USER, id, userid })
    showMessage(
      <span>
        <b>{username}</b> verwijderd uit groep.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * @FORM - Add a user to a group.
 * @param {Group} group
 * @param {User} user
 */
export const newGroupUser = ({ id }, data) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`group/${id}/user`, "post", data))
    const user = await response.json()
    dispatch({ type: ADD_USER, user })
    dispatch({ type: ADD_GROUP_USER, id, user })
    showMessage(
      <span>
        <b>{user.name}</b> aangemaakt en toegevoegd aan groep.
      </span>,
      "success"
    )
    return user
  })

/**
 * Delete a group.
 * @param {Group} group
 */
export const deleteGroup = ({ id, name }) =>
  interceptor(async dispatch => {
    await dispatch(api(`group/${id}`, "delete"))
    dispatch({ type: DELETE_GROUP, id })
    showMessage(
      <span>
        Groep <b>{name}</b> verwijderd.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * Change the sort of a group.
 * @param {Group} group
 * @param {number} position
 */
export const sortGroup = ({ id }, position) =>
  interceptor(async dispatch => {
    const response = await dispatch(
      api(`group/${id}/sort`, "patch", {
        position,
      })
    )
    const positions = await response.json()
    dispatch({ type: SORT_GROUP, positions })
    showMessage("Volgorde opgeslagen.", "success")
    return true
  }, false)

/**
 * Notify a group.
 * @param {Group} group
 */
export const notifyGroup = ({ id, name, message }) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`group/${id}/notify`, "post", { message }))
    const { totalSentCount, totalCount } = await response.json()
    showMessage(
      <span>
        <b>{totalSentCount}</b> van de <b>{totalCount}</b> leden van <b>{name}</b> gealarmeerd.
      </span>,
      "success"
    )
    return true
  }, false)

/**
 * @FORM - Save a group.
 * @param {Group} group
 */
export const saveGroup = data =>
  interceptor(async dispatch => {
    const response = await dispatch(api("group", "post", data))
    const group = await response.json()
    dispatch({ type: ADD_GROUP, group })
    showMessage(
      <span>
        Groep <b>{group.name}</b> aangemaakt.
      </span>,
      "success"
    )
    return group
  })

/**
 * @FORM - Update a group.
 * @param {Group} group
 */
export const updateGroup = ({ id, ...data }) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`group/${id}`, "patch", data))
    const group = await response.json()
    dispatch({ type: UPDATE_GROUP, id, group })
    showMessage(
      <span>
        Groep <b>{group.name}</b> gewijzigd.
      </span>,
      "success"
    )
    return group
  })

/**
 * @FORM - Add a user to a group.
 * @param {Group} group
 * @param {User} user
 */
export const newGroupDate = ({ id }, data) =>
  interceptor(async dispatch => {
    const response = await dispatch(api(`group/${id}/date`, "post", data))
    const date = await response.json()
    dispatch({ type: ADD_GROUP_DATE, id, date })
    showMessage(<span>Datum toegevoegd aan groep.</span>, "success")
    return date
  })

/**
 * Remove a user from a group.
 * @param {Group} group
 * @param {User} user
 */
export const removeGroupDate = ({ id }, { id: dateid }) =>
  interceptor(async dispatch => {
    await dispatch(api(`group/${id}/date/${dateid}`, "delete"))
    dispatch({ type: REMOVE_GROUP_DATE, id, dateid })
    showMessage(<span>Datum verwijderd van groep.</span>, "success")
    return true
  }, false)
