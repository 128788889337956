import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

const Confirm = ({
  title,
  message,
  onClick,
  onClose,
  alert,
  confirmButtonTitle,
  children,
  ...props
}) => (
  <Dialog onClose={onClose} {...props}>
    <DialogTitle>{title}</DialogTitle>
    {message ? (
      <DialogContent>
        <DialogContentText color="default">{message}</DialogContentText>
      </DialogContent>
    ) : (
      children
    )}
    <DialogActions>
      {alert ? null : (
        <Button color="primary" onClick={onClose}>
          Annuleren
        </Button>
      )}
      <Button
        color="primary"
        variant="contained"
        className="ml-2"
        onClick={alert ? onClose : onClick}
      >
        {confirmButtonTitle || "Ok"}
      </Button>
    </DialogActions>
  </Dialog>
)

export default Confirm
