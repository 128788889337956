import React from "react"
import UserForm from "../../forms/UserForm"
import AppBar from "../AppBar"
import Container from "../Container"
import Confirm from "../../dialogs/Confirm"
import Footer from "../Footer"

class EditUser extends React.PureComponent {
  state = {
    open: false,
  }

  toggleConfirm = () => {
    this.setState({ open: !this.state.open })
  }

  deleteUser = async () => {
    const { deleteUser, history, user, previous } = this.props
    if (await deleteUser(user)) {
      history.replace(previous)
    }
  }

  updateUser = async values => {
    const { updateUser, history, user, back } = this.props
    await updateUser({
      ...values,
      id: user.id,
    })
    history.replace(back)
  }

  render() {
    const { user, back } = this.props
    return (
      <React.Fragment>
        <Confirm
          title="Gebruiker verwijderen"
          message="Weet je zeker dat je deze gebruiker definitief wilt verwijderen?"
          maxWidth="xs"
          onClose={this.toggleConfirm}
          onClick={this.deleteUser}
          open={this.state.open}
        />
        <AppBar text="Gebruiker Bewerken" closeTo={back} />
        <Container>
          <UserForm onSubmit={this.updateUser} onDelete={this.toggleConfirm} initialValues={user} />
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default EditUser
