import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import history from "../../history"

import { signin, resetPassword } from "../../reducers/token"
import SigninForm from "../../forms/SigninForm"
import ResetPasswordForm from "../../forms/ResetPasswordForm"

import LogoBar from "../LogoBar"
import Container from "../Container"

import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

class Auth extends React.PureComponent {
  signin = async ({ remember, ...credentials }) => {
    const { signin } = this.props
    await signin(credentials, remember)
  }

  resetPassword = async ({ password, token }) => {
    const { resetPassword, signin } = this.props
    const { username } = await resetPassword(password, token)
    try {
      await signin({ username, password }, true)
    } catch (err) {
      history.replace("/signin")
    }
  }

  render() {
    const { classes, location } = this.props
    return (
      <React.Fragment>
        <LogoBar />
        <Switch>
          <Route
            path="/signin"
            exact
            render={props => (
              <Container className={classes.container}>
                <SigninForm onSubmit={this.signin} initialValues={{ remember: true }} />
              </Container>
            )}
          />
          <Route
            path="/activate/:token"
            exact
            render={props => (
              <Container className={classes.container}>
                <ResetPasswordForm
                  onSubmit={this.resetPassword}
                  initialValues={{ token: props.match.params.token }}
                />
              </Container>
            )}
          />
          <Route
            path="/reset/:token"
            exact
            render={props => (
              <Container className={classes.container}>
                <ResetPasswordForm
                  onSubmit={this.resetPassword}
                  initialValues={{ token: props.match.params.token }}
                />
              </Container>
            )}
          />
          <Redirect
            to={{
              pathname: "/signin",
              state: { intended: location },
            }}
          />
        </Switch>
        <Typography align="center" variant="caption" className="mt-2 py-5" noWrap>
          &copy; slimalert.nl
        </Typography>
      </React.Fragment>
    )
  }
}

const styles = {
  container: {
    maxWidth: 420,
    marginTop: 20,
  },
  logoLink: {
    width: 160,
    maxWidth: "100%",
    display: "block",
    margin: "40px auto 50px",
    padding: "20px",
  },
  logo: {
    maxWidth: "100%",
  },
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signin,
      resetPassword,
    },
    dispatch
  )

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(Auth)
)
