import React from "react"
import { Link } from "react-router-dom"
import Container from "./Container"
import LogoBar from "./LogoBar"
import Footer from "./Footer"

import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"

const Home = () => (
  <React.Fragment>
    <LogoBar />
    <Container>
      <Card>
        <List disablePadding>
          <ListItem divider button component={Link} to="/aanwezigheid">
            <ListItemText disableTypography>
              <Typography variant="subtitle1" noWrap>
                Aanwezigheid
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem divider button component={Link} to="/groepen">
            <ListItemText disableTypography>
              <Typography variant="subtitle1" noWrap>
                Alarmeringen
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem divider button component={Link} to="/accounts">
            <ListItemText disableTypography>
              <Typography variant="subtitle1" noWrap>
                Accounts
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Card>
    </Container>
    <Footer />
  </React.Fragment>
)

export default Home
