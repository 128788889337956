import React from "react"

import GroupForm from "../../../forms/GroupForm"

import AppBar from "../../AppBar"
import Container from "../../Container"
import Confirm from "../../../dialogs/Confirm"
import Footer from "../../Footer"

class EditGroup extends React.PureComponent {
  state = {
    open: false,
  }

  toggleConfirm = () => {
    this.setState({ open: !this.state.open })
  }

  deleteGroup = async () => {
    this.setState({ open: false })
    const { deleteGroup, history, group } = this.props
    if (await deleteGroup(group)) {
      history.replace("/groepen")
    }
  }

  updateGroup = async values => {
    const { updateGroup, history, group } = this.props
    if (
      await updateGroup({
        ...values,
        id: group.id,
      })
    ) {
      history.replace(`/groepen/${group.id}`)
    }
  }

  render() {
    const { group } = this.props
    return (
      <React.Fragment>
        <Confirm
          title="Groep verwijderen"
          message="Weet je zeker dat je deze groep definitief wilt verwijderen?"
          maxWidth="xs"
          onClose={this.toggleConfirm}
          onClick={this.deleteGroup}
          open={this.state.open}
        />
        <AppBar text="Groep Bewerken" closeTo={"/groepen/" + group.id} />
        <Container>
          <GroupForm
            onSubmit={this.updateGroup}
            onDelete={this.toggleConfirm}
            initialValues={group}
          />
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

export default EditGroup
