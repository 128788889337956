import React from "react"
import { Form } from "react-final-form"
import { submission, transformer, trimToString, toPhone, isPhone, isEmail } from "."
import TextField from "./fields/TextField"
import CheckboxField from "./fields/CheckboxField"

import { withStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import IconButton from "@material-ui/core/IconButton"

const transform = transformer(({ name, email, phone, ...values }) => ({
  name: trimToString(name),
  email: trimToString(email),
  phone: trimToString(phone) ? toPhone(phone) : false,
  ...values,
}))

const validate = ({ name, phone, email }) => {
  const errors = {}
  if (!name) errors.name = "Vul een naam in."
  if (!phone) errors.phone = "Vul een telefoonnummer in."
  else if (!isPhone(phone)) errors.phone = "Vul een geldig telefoonnummer in."
  if (email && !isEmail(email)) errors.email = "Vul een geldig e-mailadres in."
  return errors
}

class UserForm extends React.PureComponent {
  render() {
    const { onSubmit, onDelete, classes, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(transform(onSubmit))}
        validate={transform(validate)}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                name="name"
                inputProps={{ maxLength: 60 }}
                type="text"
                label="Naam *"
                placeholder="Naam"
                fullWidth
                className="mb-4"
              />
              <TextField
                name="phone"
                type="text"
                autoComplete="off"
                spellCheck="false"
                label="Telefoonnummer *"
                placeholder="Telefoonnummer"
                fullWidth
                className="mb-4"
              />
              <CheckboxField
                name="present"
                label={<b>Aanwezig</b>}
                color="primary"
                className="mb-4"
              />
              <CheckboxField
                name="presence_visible"
                label={<b>Aanwezigheid zichtbaar</b>}
                color="primary"
                className="mb-4"
              />
              <TextField
                name="email"
                type="text"
                helperText="E-mailadres is optioneel"
                label="E-mailadres"
                placeholder="E-mailadres"
                fullWidth
                className="mb-4"
              />
              <Grid container className={classes.buttons}>
                <Button type="submit" variant="contained" color="primary">
                  Opslaan
                  {submitting && "..."}
                </Button>
                {onDelete && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </form>
          )
        }}
      </Form>
    )
  }
}

const styles = {
  buttons: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -4px",
    "& > button": {
      margin: "4px",
    },
  },
}

export default withStyles(styles)(UserForm)
