import React, { PureComponent } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import Accounts from "./Accounts"
import CreateAccount from "./Create"
import EditAccount from "./Edit"

import { fetchAccounts, updateAccount, deleteAccount, saveAccount } from "../../reducers/accounts"

import ErrorRetry from "../ErrorRetry"
import Loading from "../Loading"

class Router extends PureComponent {
  componentWillMount() {
    this.fetchAll()
  }

  fetchAll = () => {
    this.props.fetchAccounts()
  }

  render() {
    const {
      accounts: { list: accounts, error, lastFetched },
    } = this.props

    if (!lastFetched) {
      return error ? <ErrorRetry onClick={this.fetchAll} /> : <Loading />
    }

    return (
      <Switch>
        <Route
          path="/accounts"
          exact
          render={props => <Accounts {...this.props} {...props} accounts={accounts} />}
        />
        <Route
          path="/accounts/toevoegen"
          exact
          render={props => <CreateAccount {...this.props} {...props} />}
        />
        <Route
          path="/accounts/:id"
          exact
          render={props => {
            const id = Number(props.match.params.id)
            const account = accounts.filter(account => account.id === id)[0]
            return account ? (
              <EditAccount {...this.props} {...props} account={account} />
            ) : (
              <Redirect to="/accounts" />
            )
          }}
        />
        <Redirect to="/accounts" />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  accounts: state.accounts,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccounts,
      updateAccount,
      deleteAccount,
      saveAccount,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router)
