import React from "react"
import { Field } from "react-final-form"
import MuiTextField from "@material-ui/core/TextField"

export default class TextField extends React.PureComponent {
  render() {
    const {
      classes,
      component,
      disabled,
      error: err,
      fullWidth,
      margin,
      required,
      className,
      label,
      title,
      select,
      native = true,
      ...props
    } = this.props
    return (
      <Field {...props}>
        {({ input, meta: { touched, error, submitError } }) => {
          const validationError = submitError || (touched && error)
          const hasError = err || Boolean(validationError)
          const helperText = validationError || title

          return (
            <MuiTextField
              classes={classes}
              component={component}
              disabled={disabled}
              error={hasError}
              fullWidth={fullWidth}
              margin={margin}
              required={required}
              className={className}
              label={label}
              helperText={helperText}
              select={select}
              SelectProps={{
                native,
              }}
              {...props}
              {...input}
            />
          )
        }}
      </Field>
    )
  }
}
