import React from "react"
import { Field } from "react-final-form"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

export default class CheckboxField extends React.PureComponent {
  render() {
    const { label, className, style, classes = {}, ...props } = this.props
    return (
      <Field {...props} type="checkbox">
        {({ input: { value, ...input } }) => {
          const checked = value === true
          return label ? (
            <FormControlLabel
              className={className}
              label={label}
              style={style}
              classes={{ label: classes.labelClass }}
              control={<Checkbox {...props} classes={classes} {...input} checked={checked} />}
            />
          ) : (
            <Checkbox {...props} classes={classes} {...input} checked={checked} />
          )
        }}
      </Field>
    )
  }
}
