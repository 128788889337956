import React from "react"
import { Form } from "react-final-form"
import { submission, transformer, toPhone, trimToString, isPhone } from "."
import TextField from "./fields/TextField"

import Button from "@material-ui/core/Button"

const transform = transformer(({ name, phone, ...values }) => ({
  name: trimToString(name),
  phone: trimToString(phone) ? toPhone(phone) : false,
  ...values,
}))

const validate = ({ name, phone }) => {
  const errors = {}
  if (!name) errors.name = "Vul een naam in."
  if (!phone) errors.phone = "Vul een telefoonnummer in."
  else if (!isPhone(phone)) errors.phone = "Vul een geldig telefoonnummer in."
  return errors
}

class CreateUserForm extends React.PureComponent {
  render() {
    const { onSubmit, ...props } = this.props
    return (
      <Form
        {...props}
        onSubmit={submission(transform(onSubmit))}
        validate={transform(validate)}
        subscription={{ submitting: true }}
      >
        {({ form, handleSubmit }) => {
          const { submitting } = form.getState()
          return (
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                name="name"
                inputProps={{ maxLength: 60 }}
                fullWidth
                type="text"
                label="Naam *"
                placeholder="Naam"
                className="mb-4"
              />
              <TextField
                name="phone"
                autoComplete="off"
                fullWidth
                type="text"
                label="Telefoonnummer *"
                placeholder="Telefoonnummer"
                className="mb-4"
              />
              <Button type="submit" fullWidth variant="contained" color="primary">
                Opslaan
                {submitting && "..."}
              </Button>
            </form>
          )
        }}
      </Form>
    )
  }
}

export default CreateUserForm
